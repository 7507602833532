import { gql } from '@apollo/client';

export const GET_BOOTSTRAP = gql`
  query {
    currentAccount {
      id
      name
      shortId
    }
    currentUser {
      id
      firstName
      lastName
      email
      phone
      status
      personId
      person {
        id
        type
        name
        shortId
        accountId
        description
        attributes {
          field
          value
        }
      }
      accountId
      roles {
        id
        name
        description
        createdAt
        updatedAt
        permissions {
          id
          name
          group
          slug
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`;
