import '@babel/polyfill';
import { datadogRum } from '@datadog/browser-rum';
import ReactGA from 'react-ga4';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import strings from './strings';
import theme from './theme';
import App from './routes';

import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import ScrollToTop from './components/ScrollToTop';
import NoInternetToast from './components/NoInternetToast';

import constants from './constants';

import { createLogger } from './utils/logger';
import { ChatProvider } from './providers/ChatProvider';
import { ChatContainer } from './components/ChatContainer';
import { AuthProvider } from './providers/AuthProvider';
import { ApolloProvider } from './providers/ApolloProvider';
import { FeatureFlagsProvider } from './providers/FeatureFlagsProvider';
import { ApolloInvalidationProvider } from './providers/ApolloInvalidationProvider';

const log = createLogger('app:root');

const token = localStorage.getItem(constants.STORAGE_TOKEN_KEY);

if (!token) {
  log.warn('No token found in local storage');
}

ReactGA.initialize('G-CL5RYQDC7B');

if (process.env.ENV_NAME !== 'local') {
  datadogRum.init({
    applicationId: process.env.DATADOG_APP_ID,
    clientToken: process.env.DATADOG_TOKEN,
    site: 'datadoghq.com',
    service: process.env.DATADOG_SERVICE,
    env: process.env.ENV_NAME,
    version: process.env.SHA || 'N/A',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    // allowedTracingOrigins: process.env.API_DOMAINS,
    allowedTracingUrls: process.env.API_DOMAINS,
    enableExperimentalFeatures: ['feature_flags'],
  });

  datadogRum.startSessionReplayRecording();
}

const cubejsApi = cubejs(token || '', {
  apiUrl: `${process.env.CUBE_JS_APP_BASE_URL}/cubejs-api/v1`,
});

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        maxSnack={5}
        autoHideDuration={3000}
        disableWindowBlurListener
      >
        <CubeProvider cubejsApi={cubejsApi}>
          <HelmetProvider>
            <BrowserRouter>
              <ConfirmProvider>
                <AuthProvider>
                  <ApolloProvider>
                    <ApolloInvalidationProvider>
                      <FeatureFlagsProvider>
                        <ChatProvider>
                          <Helmet
                            titleTemplate={`%s | ${strings.SITE_NAME}`}
                            defaultTitle={strings.DEFAULT_PAGE_TITLE}
                          />
                          <ScrollToTop />
                          <CssBaseline />
                          <App />
                          <ChatContainer />
                          <NoInternetToast />
                        </ChatProvider>
                      </FeatureFlagsProvider>
                    </ApolloInvalidationProvider>
                  </ApolloProvider>
                </AuthProvider>
              </ConfirmProvider>
            </BrowserRouter>
          </HelmetProvider>
        </CubeProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </>,
);
